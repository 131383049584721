<template>
  <div class="b2c-page-bg">
    <div class="register-form" v-if="!isRegistered">
      <RegisterStep :currentStep="currentStep"></RegisterStep>
      <b-row
        style="border-bottom: 1px solid #bfbfbf; padding-top: 3rem"
        class="mb-4 pb-4 mt-0"
      >
        <b-col
          cols="12"
          class="justify-content-center showOnMobile-flex d-flex"
        >
          <img
            v-if="false"
            src="@/assets/images/register-img-1.png"
            style="width: 350px; height: 400px; object-fit: contain"
          />
        </b-col>
        <b-col sm="12" lg="8">
          <b-row class="align-items-center ml-1">
            <span
              class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
            >
              1
            </span>
            <h2 class="font-weight-bold ml-1 registerStepHeader__text">
              Bước 1: Kiểm tra số
            </h2>
          </b-row>

          <b-col class="mt-4 mb-">
            <span class="h3">Số điện thoại Bạn cần đăng ký TTTB</span>
            <validation-provider
              #default="{ errors }"
              rules="required|min:2|length:10"
            >
              <b-form-input
                v-model="phone"
                :formatter="formatPhone"
                type="number"
                @update="onCheckPhone"
                placeholder="087xxx"
                style="max-width: 60rem"
                class="mt-2"
                disabled
              ></b-form-input>
              <small class="fs-error-txt" v-if="errors.length > 0"
                >Vui lòng nhập đúng số thuê bao iTel
              </small>
            </validation-provider>

            <div class="mt-2">
              <span class="h3">Số serial SIM</span>
              <validation-provider
                #default="{ errors }"
                rules="required|min:2|length:20"
              >
                <b-form-input
                  v-model="serial"
                  :formatter="formatSerial"
                  @update="onCheckSerial"
                  type="number"
                  placeholder="8984xxxx"
                  style="max-width: 60rem"
                  class="mt-2"
                  disabled
                ></b-form-input>
                <small class="fs-error-txt" v-if="errors.length > 0"
                  >Vui lòng nhập đúng 20 số serial
                </small>
              </validation-provider>
            </div>

            <b-row class="mt-4" v-if="false">
              <b-col cols="6">
                <span
                  >Cách 1: Nhập 20 số Serial trên phôi SIM <br />(Bắt đầu bằng
                  số 898xxx)</span
                >
                <br />
                <img
                  src="@/assets/images/small-sim.png"
                  style="object-fit: contain"
                  class="mt-1"
                />
              </b-col>
              <b-col cols="6">
                <span
                  >Cách 2: Nhập 20 số serial SIM được in trên khung SIM (như
                  hình minh hoạ)
                </span>
                <img
                  src="@/assets/images/scan-serial-sim-2.png"
                  style="object-fit: contain"
                  class="scanImg"
                />
              </b-col>
            </b-row>
            <p
              class="font-large-1 mt-4 font-weight-bold"
              style="color: #26b743"
            >
              Vui lòng chuyển bước 2
              <feather-icon icon="ArrowDownCircleIcon" size="18"></feather-icon>
            </p>
          </b-col>
        </b-col>
        <b-col lg="4" class="justify-content-center showOnPC">
          <img
            src="@/assets/images/register-img-1.png"
            style="width: 400px; height: 400px; object-fit: contain"
          />
        </b-col>
      </b-row>

      <div style="border-bottom: 1px solid #bfbfbf" class="mb-4 pb-4 mx-1">
        <b-row class="w-100 align-items-center mb-2 ml-1">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
          >
            2
          </p>
          <h2 class="font-weight-bold ml-1 registerStepHeader__text">
            Bước 2: Chụp ảnh
          </h2>
        </b-row>
        <!-- <p class="font-weight-bold mb-4" style="font-size: 18px">
          Ảnh 1: Mặt trước
        </p> -->
        <IdentityVerify
          :errPhoto="errPhoto"
          :removeImage="removeImage"
          @remove-image="onRemoveImage"
          @upload-image="onUploadImage"
        ></IdentityVerify>
        <p
          class="font-large-1 mt-4 font-weight-bold"
          style="color: #26b743"
          v-if="img1 && img2 && img3"
        >
          Vui lòng chuyển bước 3
          <feather-icon icon="ArrowDownCircleIcon" size="18"></feather-icon>
        </p>
      </div>
      <div
        class="mb-4 ml-2"
        style="border-bottom: 1px solid rgb(191, 191, 191)"
      >
        <b-row class="w-100 align-items-center mb-2 ml-1">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
          >
            3
          </p>
          <h2 class="font-weight-bold ml-1 registerStepHeader__text">
            Bước 3: Ký xác nhận
          </h2>
        </b-row>
        <p class="font-weight-bold mb-1" style="font-size: 16px">
          Bạn vui lòng xác nhận lại toàn bộ thông tin cá nhân
          <span style="font-style: italic"
            >(Đảm bảo thông tin là chính xác với giấy tờ tùy thân)</span
          >
        </p>
        <!--        <p class="font-weight-bold mb-4 h3" style="font-style: italic">-->
        <!--          Ghi chú: với các trường thông tin không được phép chỉnh sửa, nếu bị-->
        <!--          sai thông tin, Bạn vui lòng thực hiện các bước tiếp theo, sau đó bấm-->
        <!--          nút "Gọi Video để đăng ký thông tin" để được nhân viên hỗ trợ chỉnh-->
        <!--          sửa.-->
        <!--        </p>-->
        <b-row>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1 h3"
                >Họ và tên
                <span style="color: red; text-size: 16px">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  class="input_alarm"
                  v-model="paramsUpdate.psHoten"
                  type="text"
                  placeholder="Họ và tên"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <!--          <b-col lg="8" sm="12" class="mb-2">-->
          <!--            <b-row class="align-items-center">-->
          <!--              <b-col lg="4" style="font-weight: 500" class="p-1 h3"-->
          <!--                >Số điện thoại liên hệ <span style="color: red">*</span></b-col-->
          <!--              >-->
          <!--              <b-col lg="8">-->
          <!--                <validation-provider-->
          <!--                  #default="{ errors }"-->
          <!--                  rules="required|length:10"-->
          <!--                >-->
          <!--                  <b-form-input-->
          <!--                    type="number"-->
          <!--                    :formatter="formatPhone"-->
          <!--                    v-model="paramsUpdate.psContactPhone"-->
          <!--                    placeholder="Số điện thoại liên hệ"-->
          <!--                  ></b-form-input>-->
          <!--                  <small class="fs-error-txt" v-if="errors.length > 0"-->
          <!--                    >Vui lòng nhập số điện thoại liên hệ-->
          <!--                  </small>-->
          <!--                </validation-provider>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </b-col>-->
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1 h3"
                >Số CMND/CCCD <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <b-form-input
                  class="input_alarm"
                  type="text"
                  :value="paramsUpdate.psCmnd"
                  placeholder="Số CMND/CCCD"
                  :maxlength="maxLengthIdCard"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1 h3"
                >Ngày sinh <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <date-picker
                  class="w-100"
                  format="DD/MM/YYYY"
                  v-model="paramsUpdate.psNgaysinh"
                  valueType="format"
                  :lang="lang"
                  placeholder="Ngày sinh"
                ></date-picker>
              </b-col>
            </b-row>
          </b-col>
          <!--          <b-col lg="8" sm="12" class="mb-2">-->
          <!--            <b-row class="align-items-center">-->
          <!--              <b-col lg="4" style="font-weight: 500" class="p-1 h3"-->
          <!--                >Địa chỉ-->
          <!--                <span class="text-primary font-italic font-small-3">-->
          <!--                  (Bạn có thể sửa thông tin tại ô này nếu địa chỉ đã thay-->
          <!--                  đổi)</span-->
          <!--                >-->
          <!--              </b-col>-->

          <!--              <b-col lg="8">-->
          <!--                <validation-provider-->
          <!--                  #default="{ errors }"-->
          <!--                  rules="required|min:2"-->
          <!--                >-->
          <!--                  <b-form-input-->
          <!--                    :value="paramsUpdate.psdiachi"-->
          <!--                    v-model="paramsUpdate.psdiachi"-->
          <!--                    type="text"-->
          <!--                    placeholder="Địa chỉ"-->
          <!--                    :state="errors.length > 0 ? false : null"-->
          <!--                  ></b-form-input>-->
          <!--                  <small class="fs-error-txt" v-if="errors.length > 0"-->
          <!--                    >Vui lòng nhập địa chỉ-->
          <!--                  </small>-->
          <!--                </validation-provider>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </b-col>-->
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1 h3"
                >Ngày cấp <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <date-picker
                  class="w-100"
                  format="DD/MM/YYYY"
                  v-model="paramsUpdate.psplacedate"
                  valueType="format"
                  :lang="lang"
                  :input-class="'input_alarm mx-input'"
                  placeholder="Ngày cấp"
                ></date-picker>
                <!--                <b-form-input-->
                <!--                  v-model="paramsUpdate.psplacedate"-->
                <!--                  type="text"-->
                <!--                  placeholder="Ngày cấp"-->
                <!--                ></b-form-input>-->
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="8" sm="12" class="mb-2">
            <b-row class="align-items-center">
              <b-col lg="4" style="font-weight: 500" class="p-1 h3"
                >Nơi cấp <span style="color: red">*</span></b-col
              >
              <b-col lg="8">
                <!--                <b-form-input-->
                <!--                  disabled-->
                <!--                  :value="issuedAtName"-->
                <!--                  type="text"-->
                <!--                  placeholder="Nơi cấp"-->
                <!--                ></b-form-input>-->
                <select v-model="paramsUpdate.psnoicap" class="form-control">
                  <option
                    v-for="place in issuePlacePickList"
                    :value="place.ISSUED_PLACE_CODE"
                  >
                    {{ place.ISSUED_PLACE_NAME }}
                  </option>
                </select>
              </b-col>
            </b-row>
          </b-col>
          <!--          <b-col lg="8" sm="12" class="mb-2">-->
          <!--            <b-row class="align-items-center">-->
          <!--              <b-col lg="4" style="font-weight: 500" class="p-1 h3"-->
          <!--                >Giới tính-->
          <!--              </b-col>-->
          <!--              <b-col lg="8" class="p-1">-->
          <!--                <b-form-radio-group class="d-flex justify-content-start">-->
          <!--                  <b-form-radio-->
          <!--                    v-model="paramsUpdate.psGioiTinh"-->
          <!--                    value="male"-->
          <!--                    :checked="paramsUpdate.psGioiTinh == 'male'"-->
          <!--                    class="mr-5"-->
          <!--                  >-->
          <!--                    Nam-->
          <!--                  </b-form-radio>-->
          <!--                  <b-form-radio-->
          <!--                    v-model="paramsUpdate.psGioiTinh"-->
          <!--                    :checked="paramsUpdate.psGioiTinh == 'female'"-->
          <!--                    value="female"-->
          <!--                    >Nữ-->
          <!--                  </b-form-radio>-->
          <!--                </b-form-radio-group>-->
          <!--              </b-col>-->
          <!--            </b-row>-->
          <!--          </b-col>-->
          <b-col lg="8" sm="12">
            <p style="font-weight: 500" class="h3">
              Ký xác nhận <span style="color: red"> *</span>
              <span class="text-primary font-small-3 font-italic"
                >(Bạn vui lòng ký tên vào ô bên dưới)</span
              >
            </p>
            <b-row>
              <b-col class="showOnMobile-flex justify-content-center">
                <vueSignature
                  style="border: 1px dotted black"
                  ref="signatureMobile"
                  :w="'25rem'"
                  :h="'20rem'"
                  :sigOption="option"
                ></vueSignature>
              </b-col>
              <b-col class="d-flex justify-content-end" lg="4">
                <button
                  style="
                    background-color: #ed1f24;
                    font-weight: 500;
                    height: 50px;
                    margin-top: 2rem;
                  "
                  class="text-white p-2 rounded w-50 h3"
                  @click="clearSignature"
                >
                  Ký lại
                </button>
              </b-col>
              <b-col lg="8" class="showOnPC">
                <vueSignature
                  style="border: 1px dotted black"
                  ref="signature"
                  :w="'53rem'"
                  :h="'30rem'"
                  :sigOption="option"
                ></vueSignature>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="4">
                <p style="font-weight: 500" class="h3 mt-2">
                  Tích xác nhận <span style="color: red"> *</span>
                  <span class="text-primary font-small-3 font-italic"
                    >(Bạn vui lòng tích xác nhận)</span
                  >
                </p></b-col
              >
              <b-col lg="8">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="statusCheck"
                  value="accepted"
                  unchecked-value="not_accepted"
                  class="mt-2"
                  ><span style="font-size: 16px"
                    >Tôi hiểu và đồng ý với các điều khoản sử dụng dịch vụ của
                    Công ty Cổ phần Viễn thông Đông Dương Telecom</span
                  >
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="w-100 align-items-center my-2 d-flex flex-column-mobile">
          <b-col lg="6" sm="12" class="mb-4">
            <p style="font-weight: 500" class="h3">Xem phiếu đăng ký TTTB</p>
            <div class="d-flex justify-content-end">
              <button
                @click="onGetContact"
                class="demo-tiket-button step44Button"
              >
                <img
                  src="@/assets/images/xem-truoc-dktttb-icon.svg"
                  class="mr-1"
                />Xem phiếu
              </button>
            </div>
          </b-col>
        </div>
        <p
          class="font-large-1 py-4 font-weight-bold"
          style="color: #26b743; border-bottom: 1px solid rgb(191, 191, 191)"
          v-if="statusCheck === 'accepted'"
        >
          Vui lòng chuyển bước 4
          <feather-icon icon="ArrowDownCircleIcon" size="18"></feather-icon>
        </p>
      </div>

      <div class="mb-4 pb-4">
        <b-row class="w-100 align-items-center mb-2 ml-1">
          <p
            class="d-flex justify-content-center align-items-center text-white registerStepHeader zindex-2 font-weight-bold"
          >
            4
          </p>
          <h2 class="font-weight-bold ml-1 registerStepHeader__text">
            Bước 4: Xác thực thông tin đăng ký
          </h2>
          <!--          <span class="text-primary font-small-3 font-italic mt-1 ml-2">(Bạn vui lòng bấm nút "Gọi video" bên dưới để gặp nhân viên hỗ trợ hoàn tất quá trình đăng ký thông tin)</span>-->
          <div class="p-1" v-if="isHasOtpPhone">
            <!--            <p class="font-small-3 mt-1" >-->
            <!--              <b>Lưu ý</b>: Giấy tờ Bạn đang sử dụng để đăng ký TTTB đang đứng tên cho từ 02 thuê bao iTel trở lên.-->
            <!--              Vui lòng bấm "Gửi OTP" tới 01 thuê bao Bạn đang sử dụng để hoàn tất đăng ký TTTB.-->
            <!--              Nếu lựa chọn "Không có", vui lòng bấm nút "Gửi yêu cầu".-->
            <!--            </p>-->
            <p class="font-small-3 mt-1" style="font-style: normal">
              Lưu ý: Giấy tờ Bạn đang sử dụng để Đăng ký (Cập nhật) thông tin
              thuê bao đang đứng tên cho từ 02 số thuê bao iTel trở lên. Vui
              lòng chọn 1 trong 2 trường hợp sau:
            </p>
          </div>
        </b-row>
        <!--        <b-col sm="12" lg="6" class="d-flex justify-content-end">-->
        <!--          <button-->
        <!--              class="sent-red-button step4Button"-->
        <!--              :class="statusCheck === 'not_accepted' ? 'disabled' : ''"-->
        <!--              :disabled="statusCheck === 'not_accepted'"-->
        <!--              @click="onUpdateSub()"-->
        <!--          >-->
        <!--            Gọi video-->
        <!--          </button>-->
        <!--        </b-col>-->
        <b-row style="margin-top: 18px">
          <div class="d-flex justify-content-center w-100">
            <button
              class="btn btn-primary font-medium-3 p-1 ml-2"
              @click="onUpdateSub"
              v-if="!isHasOtpPhone"
            >
              Tiếp tục
            </button>
          </div>
          <div
            class="bg-white p-2 rounded font-medium-5 w-100"
            v-if="isHasOtpPhone"
          >
            <p class="mb-1">
              <b>Trường hợp 1: </b>Chọn 1 số thuê bao Bạn đang sử dụng, bấm nút
              "Gửi OTP"
            </p>
            <div
              class="rounded border border-2 p-2 mb-2"
              v-for="(phone, index) in sendOtpPhoneList"
            >
              <div class="d-flex justify-content-between font-medium-5">
                <b>Số thuê bao:</b>
                <p>{{ phone.phone }}</p>
              </div>
              <div class="d-flex justify-content-end mt-1">
                <b-button
                  variant="primary"
                  @click="sendOTP(phone.phone)"
                  class="font-medium-5"
                  >Gửi OTP
                </b-button>
              </div>
            </div>
          </div>
          <div
            class="bg-white p-2 rounded mt-2 font-medium-5 w-100"
            v-if="isHasOtpPhone"
          >
            <p>
              <b>Trường hợp 2: </b>Nếu Bạn không sở hữu số thuê bao nào nằm ở
              Trường hợp 1 bên trên, bấm "Tiếp tục"
            </p>
            <div class="d-flex justify-content-end mt-1">
              <b-button
                variant="primary"
                @click="showModalRegister"
                class="font-medium-5"
                >Tiếp tục
              </b-button>
            </div>
          </div>
        </b-row>
      </div>
    </div>
    <div class="main-body" v-else>
      <div class="bg-white p-2 rounded end-call-modal">
        <h4 class="font-medium-5 text-center">
          Số thuê bao đã đăng ký thành công
        </h4>
        <h4 class="font-medium-5 text-center mt-1">
          Chúc bạn một ngày tốt lành! ❤️❤️
        </h4>
        <div class="d-flex justify-content-center">
          <img src="@/assets/images/video-call/end-call-img.png" alt="" />
        </div>
      </div>
    </div>
    <b-modal id="modal-pdf" :width="800">
      <!--      <vue-pdf-embed :source="img4" ref="pdfRef" button-size="sm"/>-->
      <img :src="img4" alt="" class="w-100" />
      <template #modal-footer="{ ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <button class="btn btn-danger" style="font-size: 16px" @click="ok()">
          Đóng
        </button>
      </template>
    </b-modal>
    <b-modal
      id="modal-register"
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header
    >
      <template>
        <div class="incoming-call-container">
          <div
            class="text-center text-primary font-large-2 font-weight-bold mb-2"
          >
            Thông báo
          </div>
          <div class="font-medium-3" style="color: black">
            <p>
              Bạn xác nhận <b>KHÔNG SỬ DỤNG</b> số thuê bao iTel nào trong danh
              sách.
            </p>
            <p>
              Nếu chọn nút "<b>Đăng ký</b>" các số thuê bao gợi ý sẽ được đưa
              vào danh sách cần cập nhật lại thông tin thuê bao.
            </p>
          </div>
          <div class="d-flex justify-content-around mt-3">
            <button
              class="btn btn-primary col-3 font-medium-3 p-1"
              @click="onUpdateSub"
            >
              Đăng ký
            </button>
            <button
              class="btn btn-secondary col-3 font-medium-3 p-1"
              @click="closeModalRegister"
            >
              Quay lại
            </button>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      id="modal-otp"
      size="lg"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      centered
      hide-header
    >
      <template>
        <div class="incoming-call-container">
          <div
            class="text-center text-primary font-large-2 font-weight-bold mb-2"
          >
            Thông báo
          </div>
          <div class="font-medium-4" style="color: black">
            <p class="text-center" v-if="otpMessage.length === 0">
              Vui lòng nhập mã OTP được gửi đến số thuê bao {{ sendOtpPhone }}
            </p>
            <p class="text-center" v-if="otpMessage.length === 0">
              (Thời gian hiệu lực của OTP là 60 phút)
            </p>
            <p v-if="otpMessage.length === 0">{{ otpMessage }}</p>
            <div class="d-flex justify-content-center">
              <v-otp-input
                ref="otpInput"
                separator=""
                input-classes="otp-input"
                :num-inputs="4"
                @on-complete="regisWithOtp"
              />
            </div>
            <p v-if="otpCountDown > 0" class="text-center font-medium-4">
              Gửi lại OTP sau: {{ otpCountDown }}s
            </p>
            <p v-if="otpCountDown <= 0" class="text-center font-medium-4">
              <span
                class="text-primary cursor-pointer"
                @click="sendOTP(sendOtpPhone)"
                >Gửi lại OTP</span
              >
            </p>
          </div>
          <div class="d-flex justify-content-around my-2">
            <!--            <button class="btn btn-primary col-3 font-medium-3 p-1" @click="regisWithOtp">Đăng ký</button>-->
            <button
              class="btn btn-secondary col-3 font-medium-3 p-1"
              @click="closeModalOTP"
            >
              Quay lại
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import vueSignature from "vue-signature";
import {
  BButton,
  BCard,
  BCardHeader,
  BCardText,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTab,
  BTabs,
  TabsPlugin,
  VBTooltip,
} from "bootstrap-vue";
import Select2 from "v-select2-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import { WebCam } from "vue-web-cam";
import StarRating from "vue-star-rating";
import Fuse from "fuse.js";
import OtpInput from "@bachdgvn/vue-otp-input";
import RegisterStep from "@/components/RegisterStep.vue";
import IdentityVerify from "@/components/IdentityVerify.vue";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import useJwt from "@/auth/jwt/useJwt";
import _ from "lodash";
import axios from "axios";
import {
  getMesErrorFaceMatching,
  getMesErrorORC,
  getMesWarningFaceMatching,
} from "../@core/utils/ekycHelper";
import { $ctx } from "@themeConfig";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);
const ENDPOINT_PREVIEW = {
  registration: "pos_sub_registration_form_pdf",
};

const END_POINT_UPDATE = {
  registration: "pos_sub_registration",
};

export default {
  name: "UpdateSubscriberInformation",
  components: {
    DatePicker,
    BFormDatepicker,
    BCardHeader,
    Select2,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    FeatherIcon,
    BFormCheckbox,
    BCardText,
    BPagination,
    "vue-web-cam": WebCam,
    VBTooltip,
    StarRating,
    Fuse,
    BImg,
    TabsPlugin,
    BTabs,
    BTab,
    "v-otp-input": OtpInput,
    RegisterStep,
    vueSignature,
    IdentityVerify,
    VuePdfEmbed,
  },
  data() {
    return {
      issuedPlaceList: [],
      lang: {
        formatLocale: {
          monthsShort: [
            "Tháng 1",
            "Tháng 2",
            "Tháng 3",
            "Tháng 4",
            "Tháng 5",
            "Tháng 6",
            "Tháng 7",
            "Tháng 8",
            "Tháng 9",
            "Tháng 10",
            "Tháng 11",
            "Tháng 12",
          ],
          weekdaysMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        },
      },
      ekycTimeCount: 0,
      otpCountDownInterval: null,
      otpCountDown: 60,
      otpMessage: "",
      errPhoto: {
        img1: true,
        img2: true,
        img3: true,
      },
      sendOtpPhone: "",
      sendOtpPhoneList: [],
      currentStep: 1,
      currentUpImage: "",
      phone: "",
      serial: "",
      countDown: 60,
      modeUpdateBy: "registration",
      option: {
        penColor: "rgb(56, 63, 255)",
      },
      statusCheck: "not_accepted",
      isMatchingFace: false,
      img1: "",
      img2: "",
      img3: "",
      img4: "",
      actionCountDown: null,
      issuedAtName: "",
      paramsUpdate: {
        subscribers: [
          {
            phone: "",
            seri: "",
            seriValid: true,
            checked: false,
          },
        ],
        psHoten: "",
        psCmnd: "",
        psNgaysinh: "",
        psQuoctich: "",
        psnoicap: "",
        psdiachi: "",
        psGioiTinh: "",
        ploaigt: "",
        psplacedate: "",
        img1: "",
        img2: "",
        img3: "",
        signatureImage: "",
        psContactPhone: "",
        psHomeTown: "",
        psCityCode: "",
        faceMatching: 0,
      },
      isRegistered: false,
      removeImage: "",
    };
  },

  watch: {
    img1(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallEkyc({
          img1: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: "",
          phone: this.phone,
        })
      );
    },
    img2(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallEkyc({
          img1: this.img1
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          phone: this.phone,
        })
      );
    },
    img3(newImg, oldnewImg) {
      return (
        !_.isEmpty(newImg) &&
        this.onCallFaceMatching({
          img1: this.img1
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          img2: newImg
            .replace("data:image/jpeg;base64,", "")
            .replace("data:image/png;base64,", ""),
          phone: this.phone,
        })
      );
    },
    statusCheck() {
      return this.saveSignature();
    },
    issuedAtName() {
      return this.onCheckIssueAt();
    },
  },

  computed: {
    isHasOtpPhone() {
      return this.sendOtpPhoneList.length > 0;
    },
    maxLengthIdCard() {
      if (this.paramsUpdate.ploaigt === "1") {
        return 9;
      }
      return 12;
    },
    issuePlacePickList() {
      if (this.paramsUpdate.ploaigt == "45") {
        let list = [];
        this.issuedPlaceList.forEach((e) => {
          if (
            e.ISSUED_PLACE_CODE == "CDD" ||
            e.ISSUED_PLACE_CODE == "CQH" ||
            e.ISSUED_PLACE_CODE == "CXN"
          ) {
            list.push(e);
          }
        });
        return list;
      } else if (this.paramsUpdate.ploaigt == "1") {
        let list = [];
        this.issuedPlaceList.forEach((e) => {
          if (
            e.ISSUED_PLACE_CODE != "CDD" &&
            e.ISSUED_PLACE_CODE != "CQH" &&
            e.ISSUED_PLACE_CODE != "CXN"
          ) {
            list.push(e);
          }
        });
        return list;
      } else {
        return this.issuedPlaceList;
      }
    },
  },

  mounted() {
    const intervalFooter = setInterval(() => {
      if (window.hiddenFooter) {
        window.hideChatBot(true);
        window.hiddenFooter(false);
        clearInterval(intervalFooter);
      }
    }, 1000);
    // this.$bvModal.show("modal-otp");
    this.phone = this.$route.params.phone;
    this.serial = this.$route.params.serial;
    if (this.$route.params.key) {
      if (
        (parseInt(this.phone.slice(-3)) + 112) * 2 !=
        this.$route.params.key
      ) {
        this.isRegistered = true;
      }
    }
    this.paramsUpdate.psContactPhone = this.$route.params?.contactPhone
      ? this.$route.params.contactPhone
      : "";
    this.onCheckPhone();
    this.getIssuedPlace();
  },
  beforeDestroy() {
    window.hiddenFooter(true);
    window.hideChatBot(false);
  },
  methods: {
    logTimeEKYC() {
      axios
        .get(
          $ctx.API_CRM_URL + "/log-time-start/" + this.phone + "/" + this.serial
        )
        .then((res) => {})
        .catch((err) => {});
    },
    regisWithOtp(otp) {
      this.loading(true);
      axios
        .post($ctx.API_CRM_URL + "/check-otp-dktt", {
          otp: otp,
          phone: this.sendOtpPhone,
          msisdn: this.phone,
        })
        .then((res) => {
          this.loading(false);
          // this.onRegister();
          this.onUpdateSub();
        })
        .catch((err) => {
          this.loading(false);
          this.showAlertError(
            err.response.data.message || "Có lỗi trong quá trình kiểm tra OTP"
          );
        });
    },
    sendOTP(phone) {
      if (!this.onValidParam()) return;
      // if (_.isEmpty(this.img4)) {
      //   this.showAlertError(
      //     "Vui lòng chọn 'Xem phiếu' trước khi đăng ký TTTB."
      //   );
      //   return;
      // }
      this.loading(true);
      this.closeModalOTP();
      axios
        .post($ctx.API_CRM_URL + "/send-otp-dktt", {
          phone: phone,
          idNumber: this.paramsUpdate.psCmnd,
          msisdn: this.phone,
        })
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            this.otpMessage = "";
          } else {
            this.otpMessage = res.data.message;
          }
          this.showModalOTP();
          this.sendOtpPhone = phone;
        })
        .catch((err) => {
          console.log(err);
          this.showAlertError(
            err.response.data.message || "Có lỗi trong quá trình lấy  OTP"
          );
          this.loading(false);
        });
    },
    getPhoneByIdNumber() {
      axios
        .get(
          $ctx.API_CRM_URL +
            "/get-phone-in-idNumber/" +
            this.paramsUpdate.psCmnd
        )
        .then((res) => {
          this.sendOtpPhoneList = [];
          res.data.result.result.forEach((e) => {
            e.phone = e.phone.replace("84", "0");
            if (e.ic === "A") {
              this.sendOtpPhoneList.push(e);
            }
          });
        })
        .catch((err) => {
          this.showAlertError(
            err.response.data.message ||
              "Có lỗi trong quá trình lấy số thuê bao nhận OTP"
          );
          console.log(err);
        });
    },
    closeModalRegister() {
      this.$bvModal.hide("modal-register");
    },
    showModalRegister() {
      // if (_.isEmpty(this.img4)) {
      //   this.showAlertError(
      //     'Vui lòng chọn "Xem phiếu" trước khi đăng ký TTTB.'
      //   );
      //   return;
      // }
      this.$bvModal.show("modal-register");
    },
    closeModalOTP() {
      this.$bvModal.hide("modal-otp");
      clearInterval(this.otpCountDownInterval);
    },
    showModalOTP() {
      this.otpCountDown = 60;
      if (this.otpCountDownInterval) clearInterval(this.otpCountDownInterval);
      this.otpCountDownInterval = setInterval(() => {
        if (this.otpCountDown > 0) {
          this.otpCountDown -= 1;
        }
      }, 1000);
      this.$bvModal.show("modal-otp");
    },
    onHideOTP() {
      this.countDown = 60;
      clearTimeout(this.actionCountDown);
    },

    showAlertError(msg) {
      this.$swal({
        title: "Thông báo",
        text: msg,
        confirmButtonText: "Đóng",
      });
    },

    formatPhone(e) {
      return String(e).substring(0, 10);
    },

    formatSerial(e) {
      return String(e).substring(0, 20);
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.actionCountDown = setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    onValidParam() {
      this.saveSignature();
      if (this.modeUpdateBy == "registration" && _.isEmpty(this.serial)) {
        this.showAlertError("Vui lòng nhập số serial");
        return false;
      }

      // if (this.modeUpdateBy == "registration" && this.serial.length !== 20) {
      //   this.showAlertError("Số serial không đúng định dạng");
      //   return false;
      // }

      if (this.modeUpdateBy == "registration") {
        this.paramsUpdate.subscribers[0].seri = this.serial;
      }

      if (_.isEmpty(this.paramsUpdate.subscribers[0].phone)) {
        this.showAlertError("Vui lòng nhập số điện thoại");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img1)) {
        this.showAlertError("Vui lòng chụp ảnh mặt trước CMND");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img2)) {
        this.showAlertError("Vui lòng chụp ảnh mặt sau CMND");
        return false;
      }

      if (_.isEmpty(this.paramsUpdate.img3)) {
        this.showAlertError("Vui lòng chụp ảnh mặt người đăng ký");
        return false;
      }

      // if (_.isEmpty(this.paramsUpdate.psContactPhone)) {
      //   this.showAlertError("Vui lòng nhập số điện thoại liên hệ");
      //   return false;
      // }
      // if (
      //   !moment(this.paramsUpdate.psplacedate, "DD/MM/YYYY", true).isValid()
      // ) {
      //   this.showAlertError("Ngày cấp chưa đúng định dạng DD/MM/YYYY");
      //   return false;
      // }
      if (
        _.isEmpty(this.paramsUpdate.psCmnd) ||
        _.isEmpty(this.paramsUpdate.psHoten) ||
        _.isEmpty(this.paramsUpdate.psNgaysinh)
        // _.isEmpty(this.issuedAtName) ||
        // _.isEmpty(this.paramsUpdate.psdiachi)
      ) {
        this.showAlertError("Vui lòng kiểm tra lại các trường thông tin");
        return false;
      }

      if (!this.isMatchingFace) {
        this.showAlertError("Vui lòng kiểm tra lại ảnh chân dung");
        return false;
      }

      if (this.statusCheck == "not_accepted") {
        this.showAlertError("Vui lòng tích xác nhận thông tin");
        return false;
      }

      // if (_.isEmpty(this.paramsUpdate.psGioiTinh)) {
      //   this.showAlertError("Vui lòng chọn giới tính");
      //   return false;
      // }

      if (_.isEmpty(this.paramsUpdate.signatureImage)) {
        this.showAlertError("Vui lòng ký xác nhận");
        return false;
      }
      return true;
    },
    clearSignature() {
      this.$refs.signature.clear();
      this.$refs.signatureMobile.clear();
      this.paramsUpdate.signatureImage = "";
    },
    saveSignature() {
      var jpeg = null;
      if (!this.$refs.signature.isEmpty()) {
        jpeg = this.$refs.signature.save("image/jpeg");
      }
      if (!this.$refs.signatureMobile.isEmpty()) {
        jpeg = this.$refs.signatureMobile.save("image/jpeg");
      }
      this.paramsUpdate.signatureImage = jpeg;
      this.currentStep = 3;
    },
    onRegister() {
      if (!this.onValidParam()) return;
      // if (_.isEmpty(this.img4)) {
      //   this.showAlertError(
      //     'Vui lòng chọn "Xem phiếu" trước khi đăng ký TTTB.'
      //   );
      //   return;
      // }
      this.loading(true);
      axios
        .post($ctx.API_CRM_URL + "/pos_sub_registration_web", {
          ...this.paramsUpdate,
          hd: this.img4,
        })
        .then((response) => {
          this.loading(false);
          // this.$bvModal.hide("modal-otp");
          this.showAlertError("Đăng ký thông tin thành công");
          this.closeModalRegister();
          this.closeModalOTP();
          this.isRegistered = true;
        })
        .catch((error) => {
          this.loading(false);
          this.closeModalOTP();
          this.showAlertError(
            error.response.data.message ||
              "Có lỗi trong quá trình đăng ký thông tin"
          );
        });
    },

    onUpdateSub() {
      if (!this.onValidParam()) return;
      this.loading(true);
      axios
        .post($ctx.API_CRM_URL + "/save-log-video-call_gen_link", {
          address: this.paramsUpdate.psdiachi,
          birthDay: this.paramsUpdate.psNgaysinh,
          card_type: this.paramsUpdate.ploaigt,
          fullName: this.paramsUpdate.psHoten,
          gender: this.paramsUpdate.psGioiTinh,
          idNumber: this.paramsUpdate.psCmnd,
          img1: this.paramsUpdate.img1,
          img2: this.paramsUpdate.img2,
          img3: this.paramsUpdate.img3,
          img4: this.paramsUpdate.signatureImage,
          international: this.paramsUpdate.psQuoctich,
          issueDate: this.paramsUpdate.psplacedate,
          issuePlace: this.paramsUpdate.psnoicap,
          note: null,
          object: null,
          passport: null,
          phone: this.paramsUpdate.subscribers[0].phone.replace("0", "84"),
          seri: this.paramsUpdate.subscribers[0].seri,
          status: null,
          tdv: null,
          contactPhone: this.paramsUpdate.psContactPhone,
          homeTown: this.paramsUpdate.psHomeTown,
          cityCode: this.paramsUpdate.psCityCode,
          faceMatching: this.paramsUpdate.faceMatching,
        })
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            localStorage.setItem("callId", res.data.result.id);
            if (this.$route.params.key) {
              this.$router.push({
                name: "video-call-dktt",
                params: {
                  phone: this.phone,
                  serial: this.serial,
                  key: this.$route.params.key,
                },
              });
            } else {
              this.$router.push({
                name: "video-call-dktt-non-key",
                params: { phone: this.phone, serial: this.serial },
              });
            }
          } else {
            const message =
              res.data.message ||
              "Có lỗi trong quá trình xử lý vui lòng thử lại";
            this.showAlertError(message);
          }
        })
        .catch((err) => {
          this.loading(false);
          const message =
            err.response.data.message ||
            "Có lỗi trong quá trình xử lý vui lòng thử lại";
          this.showAlertError(message);
        });
    },

    resendOTP() {
      useJwt
        .get(`sent_otp_update/${this.phone}`)
        .then((response) => {
          this.loading(false);
          this.$toast.success("Vui lòng kiểm tra tin nhắn", {
            icon: true,
            closeButton: "button",
            timeout: 5000,
          });
          this.countDown = 60;
          this.countDownTimer();
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình gửi OTP");
          console.log(error);
        });
    },

    handleOnComplete(value) {
      this.paramsUpdate.subscribers[0].seri = value;
      this.onRegister();
    },

    previewPdf() {
      this.$bvModal.show("modal-pdf");
    },

    // onCallAPIGetOTP() {
    //   if (!this.onValidParam()) return;
    //   this.loading(true);
    //   useJwt
    //     .get(`sent_otp_update/${this.phone}`)
    //     .then((response) => {
    //       this.loading(false);
    //       this.countDownTimer();
    //       this.$bvModal.show("modal-otp");
    //     })
    //     .catch((error) => {
    //       this.loading(false);
    //       this.showAlertError("Có lỗi trong quá trình gửi OTP");
    //       console.log(error);
    //     });
    // },

    onGetContact() {
      this.saveSignature();
      const isValdParam = this.onValidParam();
      if (!isValdParam) return;
      this.loading(true);
      // axios
      //   .post(
      //     "https://appdev.itel.vn/api/v2/integration-api/pps/service-regis-contract-less-three-sub-by-teller",
      //     {
      //       pAddress: "B018 The Manor, Mễ Trì, Hà Nội",
      //       pBirthDate: this.paramsUpdate.psNgaysinh,
      //       pCCCD: this.paramsUpdate.psCmnd,
      //       pDCCDVVT: "My iTel",
      //       pDkyHoKhau: this.paramsUpdate.psHomeTown,
      //       pFullName: this.paramsUpdate.psHoten,
      //       pIsOwnersThemselves1: "1",
      //       pIssued: this.paramsUpdate.psnoicap,
      //       pIssuedDate: this.paramsUpdate.psplacedate,
      //       pNation: "Việt Nam",
      //       pRegisDate1: moment(new Date()).format("DD/MM/YYYY").toString(),
      //       pRepresentName: "",
      //       pSDT1: this.paramsUpdate.subscribers[0].phone.replace("0", "84"),
      //       pSerial1: this.paramsUpdate.subscribers[0].seri,
      //       pSign: this.paramsUpdate.signatureImage
      //         .replace("data:image/jpeg;base64,", "")
      //         .replace("data:image/png;base64,", "")
      //         .replace("data:image/jpg;base64,", ""),
      //       pTelNumber: this.paramsUpdate.psContactPhone,
      //       pTellerFullName: "Giao dịch viên", // Tên đầy đủ của tổng đài viên
      //       pTellerSign:
      //         " R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==", // Chữ ký của tổng đài viên
      //     }
      //   )
      //   .then((cardResponse) => {
      //     this.loading(false);
      //     if (cardResponse.data.code === "API000") {
      //       this.img4 = "data:image/jpeg;base64," + cardResponse?.data?.body[0];
      //       this.$bvModal.show("modal-pdf");
      //     }
      //   })
      //   .catch((err) => {
      //     this.loading(false);
      //   });
      useJwt
        .post(ENDPOINT_PREVIEW[this.modeUpdateBy], this.paramsUpdate)
        .then((response) => {
          this.loading(false);
          console.log("response", response);
          if (response.data.result.img4) {
            // this.img4 =
            //     "data:application/pdf;base64," + response.data.result.img4;
            this.img4 =
              "data:application/png;base64," + response.data.result.img4;
            this.previewPdf();
          }
        })
        .catch((error) => {
          console.log("error", error);
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình xem phiếu");
        });
    },

    onCheckPhone() {
      const first3 = this.phone.slice(0, 3);
      if (this.phone.length === 10 && first3 !== "087") {
        this.showAlertError("Số thuê bao phải theo định dạng 087xx.");
      }
      if (this.phone.length === 10 && first3 === "087") {
        // this.onCallPhone();
        this.paramsUpdate.subscribers[0].phone = this.phone;
        this.onCheckSerial();
      }
    },

    onCheckSerial() {
      this.onCallCheckSerial();
    },
    getIssuedPlace() {
      axios
        .get($ctx.API_CRM_URL + "/getIssuedPlace")
        .then((res) => {
          this.issuedPlaceList = res.data.result;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onCheckIssueAt() {
      const params = {
        type: this.paramsUpdate.ploaigt == "1" ? "CMT" : "CCCD",
        placeName: this.issuedAtName,
      };
      axios
        .post(
          "https://app.itel.vn/api/v3/integration-api/pps/issuedPlace",
          params
        )
        .then(
          (response) => {
            // console.log("response --->", response);
            this.paramsUpdate.psnoicap =
              response.data.data.onSelect.issuedPlaceCode;
            this.issuedAtName = response.data.data.onSelect.issuedPlaceName;
          },
          (error) => {
            console.log("error", error);
          }
        );
    },

    onRemoveImage(event, img) {
      this.removeImage = "";
      if (img === "img1") {
        this.img1 = "";
        this.paramsUpdate.psHoten = "";
        this.paramsUpdate.psCmnd = "";
        this.paramsUpdate.psNgaysinh = "";
        this.paramsUpdate.psQuoctich = "";
        this.paramsUpdate.psdiachi = "";
        this.paramsUpdate.psGioiTinh = "";
        this.sendOtpPhoneList = [];
        this.img4 = "";
      }
      if (img === "img2") {
        this.img2 = "";
        this.issuedAtName = "";
        this.paramsUpdate.psplacedate = "";
        this.img4 = "";
      }
      if (img === "img3") {
        this.img3 = "";
        this.isMatchingFace = false;
      }
    },

    onUploadImage(image, type) {
      if (type === "frontCMND") {
        this.currentUpImage = "frontCMND";
        this.img1 = image;
        this.paramsUpdate.img1 = image;
      }
      if (type === "backCMND") {
        this.currentUpImage = "backCMND";
        this.img2 = image;
        this.paramsUpdate.img2 = image;
      }
      if (type === "profilePicture") {
        this.currentUpImage = "profilePicture";
        this.img3 = image;
        this.paramsUpdate.img3 = image;
      }
    },

    removeErrImage() {
      if (this.currentUpImage === "frontCMND") {
        this.errPhoto.img1 = true;
        this.removeImage = "img1";
      }
      if (this.currentUpImage === "backCMND") {
        this.errPhoto.img2 = true;
        this.removeImage = "img2";
      }
    },

    onCallEkyc(params) {
      this.loading(true);
      axios
        .post(
          "https://itel.vn/api/web/ovs/ekyc/cards?format_type=base64&get_thumb=false",
          params
        )
        .then((res) => {
          this.loading(false);
          if (this.ekycTimeCount === 0) {
            this.logTimeEKYC();
            this.ekycTimeCount++;
          }
          const infoCardFont = _.find(res.data.result.data, (item) => {
            return _.includes(item.type, "front");
          });
          const infoCardBack = _.find(res.data.result.data, (item) => {
            return _.includes(item.type, "back");
          });
          if (
            !_.isEmpty(infoCardFont) &&
            !_.isEmpty(infoCardBack) &&
            this.img1 &&
            this.img2
          ) {
            const typeCardBack = infoCardBack?.type.substring(0, 4);
            const typeCardFont = infoCardFont?.type.substring(0, 4);
            const isSamePaper = typeCardBack === typeCardFont;
            if (!isSamePaper) {
              return this.showAlertError("Giấy tờ tuỳ thân không đồng nhất!");
            }
          }
          const errorCode = res.data.result.errorCode;
          if (errorCode !== 0) {
            this.loading(false);
            if (_.isEmpty(infoCardFont) && !_.isEmpty(this.img1)) {
              this.removeErrImage();
              const errorMessage = getMesErrorORC(errorCode);
              return this.showAlertError(errorMessage);
            }
            if (_.isEmpty(infoCardBack) && !_.isEmpty(this.img2)) {
              this.removeErrImage();
              const errorMessage = getMesErrorORC(errorCode);
              return this.showAlertError(errorMessage);
            }

            // const errorMessage = getMesErrorORC(errorCode);
            // return this.showAlertError(errorMessage);
          }

          if (this.img1 && this.img2) {
            if (res.data.result.data.length === 1) {
              res.data.result.data[0].type.includes("front")
                ? (this.errPhoto.img2 = true)
                : (this.errPhoto.img1 = true);
              this.removeImage = res.data.result.data[0].type.includes("front")
                ? "img2"
                : "img1";
              return this.showAlertError("Giấy tờ tuỳ thân không hợp lệ!");
            }
          }
          // if (!_.isEmpty(infoCardFont?.info?.due_date)) {
          //   if (
          //     this.checkExpirationDateIsOver(infoCardFont?.info?.due_date) &&
          //     !_.isEmpty(this.img1)
          //   ) {
          //     this.removeErrImage();
          //     this.errPhoto.img1 = true;
          //     this.loading(false);
          //     return this.showAlertError(
          //       "Giấy tờ tuỳ thân đã hết hạn sử dụng!"
          //     );
          //   }
          // }

          if (infoCardBack.type.includes("9_id")) {
            if (!_.isEmpty(infoCardBack?.info?.issue_date)) {
              if (
                this.checkExpirationDateIsOver(
                  infoCardBack?.info?.issue_date,
                  true
                ) &&
                !_.isEmpty(this.img2)
              ) {
                this.removeErrImage();
                this.errPhoto.img2 = true;
                return this.showAlertError(
                  "Giấy tờ tuỳ thân đã hết hạn sử dụng!"
                );
              }
            }
          }

          // console.log("infoCardFont --->", infoCardFont);
          // console.log("infoCardBack --->", infoCardBack);
          // const invalidCodeF = infoCardFont?.invalidCode;
          // const invalidCodeB = infoCardBack?.invalidCode;

          // console.log("invalidCodeF-->", invalidCodeF);
          // console.log("invalidCodeB-->", invalidCodeB);
          // const invalidMessageF = getMesWarningORC(invalidCodeF);
          // const invalidMessageB = getMesWarningORC(invalidCodeB);

          // if (invalidMessageF) {
          //   this.errPhoto.img1 = true;
          //   this.removeImage='img1';
          //   return this.showAlertError(invalidMessageF);
          // }
          // if (invalidMessageB) {
          //   this.removeImage='img2';
          //   this.errPhoto.img2 = true;
          //   return this.showAlertError(invalidMessageB);
          // }
          this.paramsUpdate.ploaigt = _.includes(
            res.data.result.data[0].type,
            "9_id"
          )
            ? "1"
            : "45";
          if (infoCardFont) {
            this.errPhoto.img1 = false;
            this.paramsUpdate.psHomeTown = infoCardFont.info.hometown;
            this.paramsUpdate.psHoten = infoCardFont.info.name;
            this.paramsUpdate.psCmnd = infoCardFont.info.id;
            this.paramsUpdate.psNgaysinh = infoCardFont.info.dob.replace(
              /-/g,
              "/"
            );
            this.paramsUpdate.psCityCode = infoCardFont.info.address_town;
            this.paramsUpdate.psQuoctich = "232";
            this.paramsUpdate.psdiachi = infoCardFont.info.address;
            this.paramsUpdate.psGioiTinh = infoCardFont.info.gender
              ? _.toLower(infoCardFont.info.gender) == "nữ"
                ? "female"
                : "male"
              : "";
            this.onCheckIdNumber();
          }
          if (infoCardBack) {
            this.errPhoto.img2 = false;
            this.issuedAtName = infoCardBack.info.issued_at;
            // this.paramsUpdate.psnoicap = infoCardBack.info.issued_at;
            this.paramsUpdate.psplacedate =
              infoCardBack.info.issue_date.replace(/-/g, "/");

            document
              .getElementById("beginImg3")
              .scrollIntoView({ behavior: "smooth" });
            if (_.isEmpty(this.img1)) {
              this.$toast.success("Ảnh chụp thành công", {
                icon: true,
                timeout: 4000,
              });
            }
          }
        })
        .catch((err) => {
          this.loading(false);
          this.showAlertError("Có lỗi trong quá trình kiểm tra ảnh");
        });
    },
    checkExpirationDateIsOver(dueDate, isCMT = false) {
      if (
        dueDate.toUpperCase() === "KHONG THOI HAN" ||
        dueDate === "00/00/1900"
      ) {
        return false;
      }
      if (isCMT) {
        return dayjs().isSameOrAfter(
          dayjs(dueDate, "DD-MM-YYYY").add(15, "year")
        );
      }
      return dayjs().isSameOrAfter(dayjs(dueDate, "DD/MM/YYYY"));
    },
    onCallFaceMatching(params) {
      this.loading(true);
      axios
        .post(
          "https://itel.vn/api/web/ovs/ekyc/face_matching?format_type=base64&type1=card",
          params
        )
        .then((res) => {
          this.currentStep = 3;
          this.loading(false);
          const errorCode = res.data.result.errorCode;
          if (errorCode !== "0") {
            this.removeImage = "img3";
            this.errPhoto.img3 = true;
            const errorMessage = getMesErrorFaceMatching(errorCode);
            return this.showAlertError(errorMessage);
          }

          const invalidCode = res.data.result.data.invalidCode;
          const warningMess = getMesWarningFaceMatching(invalidCode);
          if (warningMess) {
            this.removeImage = "img3";
            this.errPhoto.img3 = true;
            return this.showAlertError(warningMess);
          }
          const isMatching = res.data.result.data.matching > 60;
          if (!isMatching) {
            this.removeImage = "img3";
            this.errPhoto.img3 = true;
            return this.showAlertError(
              "Ảnh chân dung không khớp với ảnh giấy tờ "
            );
          }
          this.errPhoto.img3 = false;
          this.paramsUpdate.faceMatching = res.data.result.data.matching;
          this.isMatchingFace = true;
        })
        .catch((err) => {
          this.loading(false);
          this.showAlertError(
            "Có lỗi trong quá trình kiểm tra độ trùng khớp mặt "
          );
        });
    },

    onCheckIdNumber() {
      if (!_.isEmpty(this.phone) && !_.isEmpty(this.paramsUpdate.psCmnd)) {
        const params = {
          phone: this.phone,
          idNumber: this.paramsUpdate.psCmnd,
        };
        this.loading(true);
        useJwt
          .get(`check_id_number/${params.phone}/${params.idNumber}`)
          .then((res) => {
            this.loading(false);
            this.getPhoneByIdNumber();
            this.$toast.success("Ảnh chụp thành công", {
              icon: true,
              timeout: 4000,
            });
            if (_.isEmpty(this.img2)) {
              document
                .getElementById("beginImg2")
                .scrollIntoView({ behavior: "smooth" });
            }
          })
          .catch((error) => {
            this.loading(false);
            this.errPhoto.img1 = true;
            this.removeImage = "img1";
            this.showAlertError(error.response.data.message);
          });
      }
    },

    onCallCheckSerial() {
      this.loading(true);
      const params = [
        {
          phone: this.phone,
          seri: this.serial,
        },
      ];
      useJwt
        .post("check_msisdn_seri", params)
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            this.paramsUpdate.subscribers[0].serial = this.serial;
            this.paramsUpdate.subscribers[0].seriValid = true;
            return (this.currentStep = 2);
          }
          this.isRegistered = true;
        })
        .catch((error) => {
          this.loading(false);
          this.showAlertError(error.response.data.message);
        });
    },

    onCallPhone() {
      this.loading(true);
      useJwt
        .get("check_sub_registration/" + this.phone)
        .then((res) => {
          this.loading(false);
          if (res.data.code === 200) {
            this.paramsUpdate.subscribers[0].phone = this.phone;
            this.paramsUpdate.subscribers[0].seriValid = true;
            return (this.currentStep = 2);
          }
        })
        .catch((error) => {
          this.loading(false);
          // this.modeUpdateBy = "otp";
          this.serial = "";
          this.showAlertError(error.response.data.message);
        });
    },
  },
};
</script>

<style scoped>
.registerButton {
  width: 25%;
}

.register-form {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  font-size: medium;
  font-family: "roboto";
  font-weight: 400;
}

.registerStepHeader__text {
  font-size: 24px;
  width: 80%;
}

.registerStepHeader {
  background-color: #ed1f24;
  border-radius: 50%;
  height: 55px;
  width: 55px;
}

.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}

.showOnMobile {
  display: none !important;
}

.showOnTablet {
  display: none !important;
}

.showOnMobile-flex {
  display: none !important;
}

.showOnPC {
  display: block !important;
}

.showOnPC-flex {
  display: flex !important;
}

.step4Button {
  font-weight: 600;
  font-size: 12px;
  width: 160px;
  height: 48px;
  border-radius: 5px;
}

.step44Button {
  font-weight: 600;
  font-size: 12px;
  width: 160px;
  height: 48px;
  border-radius: 5px;
}

.sent-red-button {
  color: white;
  font-size: 16px;
  background-color: #ea0029;
  border: 1px solid #ea0029;
}

.demo-tiket-button {
  color: #7c7c7c;
  background: #ffffff;
  border: 1px solid #ed1f24;
}

@media screen and (max-width: 1080px) {
  .register-form {
    font-size: 12px;
    max-width: 75rem;
  }

  .registerStepHeader {
    height: 2.5rem;
    width: 2.5rem;
  }

  .registerStepHeader__text {
    font-size: 14px;
  }

  .inputSerial {
    max-width: 55rem !important;
  }

  .scanImg {
    margin-left: -3rem;
  }

  .col-xs-mobile {
    width: 25%;
  }

  .sent-red-button {
    margin-bottom: 1rem;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnTablet {
    display: block !important;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }

  .registerButton {
    width: 50%;
  }
}

@media screen and (max-width: 450px) {
  .register-form {
    font-size: 12px;
    max-width: 35rem;
  }

  .inputSerial {
    max-width: 32rem !important;
  }

  .scanImg {
    margin-left: -3rem;
  }

  .registerStepHeader {
    height: 2.5rem;
    width: 2.5rem;
  }

  .registerStepHeader__text {
    font-size: 18px;
  }

  .col-xs-mobile {
    width: 25%;
  }

  .flex-column-mobile {
    flex-direction: column;
  }

  .ml-1-mobile {
    margin-left: 1rem;
  }

  .showOnMobile {
    display: block !important;
  }

  .showOnMobile-flex {
    display: flex !important;
  }

  .showOnPC {
    display: none !important;
  }

  .showOnPC-flex {
    display: none !important;
  }

  .registerButton {
    width: 100%;
  }
}
</style>
<style>
.input_alarm {
  font-size: 1.8rem;
  color: #ed1e23;
  font-weight: bold !important;
  line-height: 6rem;
  padding: 0.5rem 1rem !important;
}

.mx-input {
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  height: 50px;
}

.otp-input {
  width: 4rem;
  height: 4rem;
  margin: 1rem 2rem;
  font-size: 24px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.otp-input.error {
  border: 1px solid red !important;
}

.modal-dialog {
  max-width: 850px;
}

#modal-otp {
  margin-left: 35%;
  max-width: 50rem;
}

@media screen and (max-width: 1080px) {
  #modal-otp {
    margin-left: 20%;
  }
}

@media screen and (max-width: 450px) {
  .otp-input {
    width: 3rem;
    height: 3rem;
    margin: 1rem 2rem;
    font-size: 12px;
  }

  #modal-otp {
    margin-left: 0;
  }
}

@media screen and (max-width: 300px) {
  .otp-input {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0.5rem 1rem;
    font-size: 12px;
  }
}
</style>
